import React from "react"

import { Paper, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "1em",
    backgroundColor: "transparent",
    color: "white",
  },
}));

const Biography = () => {
  const classes = useStyles();
  return (
    <Paper elevation={0} className={classes.paper}>
      <Box letterSpacing={3}>
        <Typography variant="h3">岡戸 竹 / Okado Takeru</Typography>
        <Box margin="3em 0">
          <Box margin="1em 0">
            <Typography variant="h4">作家略歴</Typography>
          </Box>
          <dl>
            <dt><Typography variant="h6">1993</Typography></dt>
            <dd><Typography variant="body1">埼玉県生まれ</Typography></dd>
          </dl>
          <dl>
            <dt><Typography variant="h6">2016</Typography></dt>
            <dd>
              <Typography variant="body1">東北芸術工科大学　漆芸専攻　卒業</Typography>
            </dd>
          </dl>
        </Box>
        <Box margin="3em 0">
          <Box margin="1em 0">
            <Typography variant="h4">展示歴</Typography>
          </Box>
          <dl>
            <dt><Typography variant="h6">2023</Typography></dt>
            <dd>
              <ul>
                <li><Typography variant="body1">「第79回金沢市工芸展」金沢エムザ、石川　入選</Typography></li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt><Typography variant="h6">2022</Typography></dt>
            <dd>
              <ul>
                <li><Typography variant="body1">「第78回金沢市工芸展」金沢エムザ、石川　入選</Typography></li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt><Typography variant="h6">2019</Typography></dt>
            <dd>
              <ul>
                <li><Typography variant="body1">「第2回枕崎国際芸術賞展」枕崎市文化資料センター南溟館、鹿児島 入選</Typography></li>
                <li><Typography variant="body1">「アートガイア@城南島 現代アート展」城南島ARTFACTORY、東京 入選</Typography></li>
                <li><Typography variant="body1">「第75回金沢市工芸展」めいてつエムザ、石川 入選</Typography></li>
                <li><Typography variant="body1">「第43回三菱アートゲートプログラム」三菱商事、東京 入選</Typography></li>
                <li><Typography variant="body1">「ACT アート大賞展 2019」ACT 館、東京 入選</Typography></li>
              </ul>
            </dd>
          </dl>
          <dl>
            <dt><Typography variant="h6">2018</Typography></dt>
            <dd>
              <ul>
                <li><Typography variant="body1">「第74回金沢市工芸展」めいてつエムザ、石川 入選</Typography></li>
                <li><Typography variant="body1">「第42回三菱アートゲートプログラム」丸の内パークビルディング、東京 入選</Typography></li>
              </ul>
            </dd>
          </dl>
        </Box>
      </Box>
    </Paper>
  );
};
export default Biography;