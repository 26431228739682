import React from "react"
import Layout from "../components/layout";
import BiographyContent from "../components/biography";

const Biography = () => {
  return (
    <Layout id="biography" title="Biography">
      <BiographyContent />
    </Layout>
  );
};
export default Biography;